import React from 'react';
import { Container , Row , Col  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import nawaar from '../../../Assets/img/nawaar.png';
import savepoints from '../../../Assets/img/savepoints.png';
import mosabqat from '../../../Assets/img/mosabqat.png';
import smaa from '../../../Assets/img/smaa.png';
import steel from '../../../Assets/img/steel.png';
import Twasul from '../../../Assets/img/twasul.png';
import bookStore from '../../../Assets/img/bookStore.png';
import baseet from '../../../Assets/img/baseet.png';
import sada from '../../../Assets/img/sada.png';



import './ProjectSec.scss';
const ProjectSec = () => {
    return ( 
        <>
            <section className="projects-sec" id="projects">
                <Container>
                    <Row>
                        <Col>
                            <div className="sec-heading">
                                <h2>
                                    <span className="green-txt">03. </span>  
                                    Some Things I’ve Built
                                </h2>
                                <span className="line"></span>
                            </div>

                            <div className="projects-parent">

                                {/* twasul website  */}
                                <div className="cont">
                                    <div className="cont-txt">
                                        <span className="green-txt">Featured Project</span>
                                        <h2 className="project-name">
                                            <a href="https://twasul.info/" target="_blank" rel='noreferrer'>Twasul website</a>
                                        </h2>

                                        <div className="description">
                                            <p>Tawasul electronic newspaper website is an advertising window through which your products and services reach millions of views </p>
                                        </div>

                                        <div className="tools">
                                            <span>sass</span>
                                            <span>React js</span>
                                            <span>Wordprss( API )</span>
                                            <span>Npm Packages</span>
                                        </div>
                                        <div className="links-parent">
                                            <a href="https://twasul.info/" target="_blank" rel='noreferrer'>
                                                <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="cont-img">
                                        <div className="img-parent">
                                            <img src={Twasul} alt="twasul website" />
                                        </div>
                                    </div>
                                </div>

                                {/* baseet webite  */}

                                <div className="cont">
                                    <div className="cont-txt">
                                        <span className="green-txt">Featured Project</span>
                                        <h2 className="project-name">
                                            <a href="https://baseet.smartwaveeg.com/home" target="_blank" rel='noreferrer'>Baseet</a>
                                        </h2>

                                        <div className="description">
                                            <p>An educational platform for all students and teachers
                                                Whether you are a student, teacher, researcher or parent. You deserve more outside of the semester! </p>
                                        </div>

                                        <div className="tools">
                                            <span>sass</span>
                                            <span>Angular</span>
                                            <span>Npm Packages</span>
                                        </div>
                                        <div className="links-parent">
                                            <a href="https://baseet.smartwaveeg.com/home" target="_blank" rel='noreferrer'>
                                                <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="cont-img">
                                        <div className="img-parent">
                                            <img src={baseet} alt="Baseet" />
                                        </div>
                                    </div>
                                </div>

                                {/* book store webite  */}

                                <div className="cont">
                                    <div className="cont-txt">
                                        <span className="green-txt">Featured Project</span>
                                        <h2 className="project-name">
                                            <a href="https://skitab.projects.dimofinf.net/" target="_blank" rel='noreferrer'>Swaani book</a>
                                        </h2>

                                        <div className="description">
                                            <p>An electronic market and a Saudi cultural platform, to facilitate the process of buying, selling, and circulating books among members of society.</p>
                                        </div>

                                        <div className="tools">
                                            <span>sass</span>
                                            <span>React js</span>
                                            <span>Npm Packages</span>
                                        </div>
                                        <div className="links-parent">
                                            <a href="https://skitab.projects.dimofinf.net/" target="_blank" rel='noreferrer'>
                                                <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="cont-img">
                                        <div className="img-parent">
                                            <img src={bookStore} alt="swaani bookstore" />
                                        </div>
                                    </div>
                                </div>

                                {/* sada website  */}
                                <div className="cont">
                                    <div className="cont-txt">
                                        <span className="green-txt">Featured Project</span>
                                        <h2 className="project-name">
                                            <a href="https://slaati.com/" target="_blank" rel='noreferrer'>Sada News</a>
                                        </h2>

                                        <div className="description">
                                            <p>website for Newspaper , get all the new news and blog in saudi arabia</p>
                                        </div>

                                        <div className="tools">
                                            <span>sass</span>
                                            <span>React js</span>
                                            <span>Wordpress(API)</span>
                                            <span>Npm Packages</span>
                                        </div>
                                        <div className="links-parent">
                                            <a href="https://slaati.com/" target="_blank" rel='noreferrer'>
                                                <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="cont-img">
                                        <div className="img-parent">
                                            <img src={sada} alt="sada news" />
                                        </div>
                                    </div>
                                </div>

                                <div className="cont">
                                    <div className="cont-txt">
                                        <span className="green-txt">Featured Project</span>
                                        <h2 className="project-name">
                                            <a href="https://smaa.sa.com/" target="_blank" rel='noreferrer'>smaa website</a>
                                        </h2>

                                        <div className="description">
                                            <p>A classified Ads website which allows users to add their ads and sell products.</p>
                                        </div>

                                        <div className="tools">
                                            <span>sass</span>
                                            <span>React js</span>
                                            <span>Npm Packages</span>
                                        </div>
                                        <div className="links-parent">
                                            <a href="https://smaa.sa.com/" target="_blank" rel='noreferrer'>
                                                <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="cont-img">
                                        <div className="img-parent">
                                            <img src={smaa} alt="smaa website" />
                                        </div>
                                    </div>
                                </div>

                                <div className="cont">
                                    <div className="cont-txt">
                                        <span className="green-txt">Featured Project</span>
                                        <h2 className="project-name">
                                            <a href="https://savepoints.sa/" target="_blank" rel='noreferrer'>Save Points</a>
                                        </h2>

                                        <div className="description">
                                            <p>Dashboard to make Vendors with multiple permissions control their products , branches and employees and receive reports for invoices and control every thing.</p>
                                        </div>

                                        <div className="tools">
                                            <span>sass</span>
                                            <span>React js</span>
                                            <span>Npm Packages</span>
                                        </div>
                                        <div className="links-parent">
                                            <a href="https://savepoints.sa/" target="_blank" rel='noreferrer'>
                                                <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="cont-img">
                                        <div className="img-parent">
                                            <img src={savepoints} alt="save points website" />
                                        </div>
                                    </div>
                                </div>


                                <div className="cont">
                                    <div className="cont-txt">
                                        <span className="green-txt">Featured Project</span>
                                        <h2 className="project-name">
                                            <a href="https://mosabaqat.projects.dimofinf.net/" target="_blank" rel='noreferrer'>Mosabqat</a>
                                        </h2>

                                        <div className="description">
                                            <p>its an competition web app using React Library and sass to let the users to compeat with each others to earn coins </p>
                                        </div>

                                        <div className="tools">
                                            <span>sass</span>
                                            <span>React js</span>
                                            <span>Npm Packages</span>
                                        </div>
                                        <div className="links-parent">
                                            <a href="https://mosabaqat.projects.dimofinf.net/" target="_blank" rel='noreferrer'>
                                                <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="cont-img">
                                        <div className="img-parent">
                                            <img src={mosabqat} alt="" />
                                        </div>
                                    </div>
                                </div>


                                <div className="cont">
                                    <div className="cont-txt">
                                        <span className="green-txt">Featured Project</span>
                                        <h2 className="project-name">
                                            <a href="https://nawwar.co/" target="_blank" rel='noreferrer'>Halcyon Theme</a>
                                        </h2>

                                        <div className="description">
                                            <p> Nawwar is a design studio that brings content to life through the power of animation, live videos and learning experience design.</p>
                                        </div>

                                        <div className="tools">
                                            <span>Wordpress</span>
                                        </div>
                                        <div className="links-parent">
                                            <a href="https://nawwar.co/" target="_blank" rel='noreferrer'>
                                                <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="cont-img">
                                        <div className="img-parent">
                                            <img src={nawaar} alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="cont">
                                    <div className="cont-txt">
                                        <span className="green-txt">Featured Project</span>
                                        <h2 className="project-name">
                                            <a href="https://a-halimsteel.com/" target="_blank" rel='noreferrer'>Abdul Halim Sons Company</a>
                                        </h2>

                                        <div className="description">
                                            <p> Abdul Halim Sons Company specialized in the field of iron and stainless-steel trade.</p>
                                        </div>

                                        <div className="tools">
                                            <span>Wordpress</span>
                                        </div>
                                        <div className="links-parent">
                                            <a href="https://a-halimsteel.com/" target="_blank" rel='noreferrer'>
                                                <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="cont-img">
                                        <div className="img-parent">
                                            <img src={steel} alt="" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
        </>
    );
}

export default ProjectSec;