import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Container , Row , Col } from 'react-bootstrap';
import './WorkSec.scss'
const WorkSec = () => {
    return ( 
        <>  
            <section className="work-sec" id="workSec">
                <Container>
                    <Row>
                        <Col xs={12} lg={8} className="mx-auto">
                            <div className="sec-heading">
                                <h2>
                                    <span className="green-txt">02. </span>  
                                    Where I’ve Worked
                                </h2>
                                <span className="line"></span>
                            </div>
                            <Tabs className="work-links-parent">
                                <TabList className="work-links-controls">
                                    <Tab>Dimofinf</Tab>
                                    <Tab>Freelance</Tab>
                                    <Tab>IsoftLand</Tab>
                                    <Tab>Infotimes</Tab>
                                    <Tab>Souq Aqar</Tab>
                                    <Tab>Intermark Network</Tab>
                                </TabList>
                                <div className="tabs-parent">
                                    <TabPanel>
                                        <div className="cont-txt">
                                            <h3>Front End Developer <a href="https://www.dimofinf.net/" target='_blank' className="green-txt">Dimofinf</a></h3>
                                            <p className="time">April 2019 - Present</p>
                                            <ul className="description">
                                                <li>Develop websites with react js</li>
                                                <li>Develop wordpress websites and Plugins</li>
                                                <li>Make the ui section of website and integrate code with Api</li>
                                                <li>Build reusable Components and libraries for future use</li>
                                                <li>Optimize application for maximum speed and scalability</li>
                                                <li>Collaborate with other team members and stakeholders</li>
                                                <li>Managing software workflow</li>
                                                <li>Fixing bugs and testing for usability</li>
                                                <li>Following SEO best practices</li>
                                            </ul>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="cont-txt">
                                            <h3>Front End Developer <a href="#" className="green-txt">Freelance</a></h3>
                                            <p className="time">september 2015 - present</p>
                                            <ul className="description">
                                                <li>Creating static websites pages with html, css, js,angularjs,  reactjs</li>
                                                <li>Installing & editing some WordPress themes for clients ,</li>
                                                <li>Fix errors to reach stability to launch</li>
                                            </ul>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="cont-txt">
                                            <h3>Front End Developer <a href="#" className="green-txt">IsoftLand</a></h3>
                                            <p className="time">November 2018 - june 2019</p>
                                            <ul className="description">
                                                <li>Convert simple ui designs into websites based on Angular js</li>
                                                <li>Integrate the client side with the back-end</li>
                                            </ul>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="cont-txt">
                                            <h3>Front End Developer <a href="#" className="green-txt">Infotimes</a></h3>
                                            <p className="time">May 2017 - November 2018</p>
                                            <ul className="description">
                                                <li>My role is to keep developing the company sites based on wordpress, repair the problems that face the users while using them and keep updating the front end to reach the stability and user requirement</li>
                                            </ul>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="cont-txt">
                                            <h3>Front End Developer <a href="#" className="green-txt">Souq Aqar</a></h3>
                                            <p className="time">December 2017 - June 2018</p>
                                            <ul className="description">
                                                <li>My role is to keep developing the company sites based on wordpress, repair the problems that face the users while using them and keep updating the front end to reach the stability and user requirement</li>
                                            </ul>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="cont-txt">
                                            <h3>Front End Developer <a href="#" className="green-txt">Intermark Network</a></h3>
                                            <p className="time">February 2017 - December 2017</p>
                                            <ul className="description">
                                                <li>Convert e-detailing presentation slides from PSD to HTML & CSS with animation</li>
                                            </ul>
                                        </div>
                                    </TabPanel>
                                </div>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default WorkSec;