import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF , faGithub, faInstagram , faLinkedinIn , faCodepen ,  faTwitter } from '@fortawesome/free-brands-svg-icons';
import './Footer.scss';
const ContactSec = () => {
    return ( 
        <>
            <footer className="contact-sec" id="contact">
                <div className="cont">
                    <div className="heading green-txt">
                        <span className="number">04.</span>
                        <p>What's Next?</p>
                    </div>
                    <h2>Get In Touch</h2>
                    <p>
                        Although I’m not currently looking for any new opportunities, my inbox is always open. Whether you have a question or just want to say hi, I’ll try my best to get back to you!
                    </p>
                    <a href="mailto:s4hk@yahoo.com" className="transparent-btn">
                        Say Hello
                    </a>
                </div>

                <div className="footer-social-icons-parent">
                    <a href="https://github.com/s4hk" className="social-icon" target="_blank" rel='noreferrer'>
                        <FontAwesomeIcon icon={faGithub}/>
                    </a>

                    <a href="https://www.facebook.com/2seif" className="social-icon" target="_blank" rel='noreferrer'>
                        <FontAwesomeIcon icon={faFacebookF}/>
                    </a>

                    <a href="https://www.instagram.com/s4hk/" className="social-icon" target="_blank" rel='noreferrer'>
                        <FontAwesomeIcon icon={faInstagram}/>
                    </a>

                    <a href="https://twitter.com/mr_s4hk" className="social-icon" target="_blank" rel='noreferrer'>
                        <FontAwesomeIcon icon={faTwitter}/>
                    </a>

                    <a href="https://www.linkedin.com/in/seifo/" className="social-icon" target="_blank" rel='noreferrer'>
                        <FontAwesomeIcon icon={faLinkedinIn}/>
                    </a>

                    <a href="https://codepen.io/s4hk" className="social-icon" target="_blank" rel='noreferrer'>
                        <FontAwesomeIcon icon={faCodepen}/>
                    </a>
                    <span className="line"></span>
                </div>
                <div className="copy-rights">
                    <a href="https://brittanychiang.com/" className="link green-txt"   target="_blank" rel='noreferrer'>Designed by Brittany</a> & 
                    <a href="/" className="green-txt">Built By Seif Atalla</a>
                </div>

            </footer>
        </>
    );
}

export default ContactSec;