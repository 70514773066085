import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF , faGithub, faInstagram , faLinkedinIn , faCodepen ,  faTwitter } from '@fortawesome/free-brands-svg-icons';
import './Contact.scss'
const Contact = () => {
    return ( 
        <>
            <div className="social-icons-parent">
                <a href="https://github.com/s4hk" className="social-icon" target="_blank" rel='noreferrer'>
                    <FontAwesomeIcon icon={faGithub}/>
                </a>

                <a href="https://www.facebook.com/2seif" className="social-icon" target="_blank" rel='noreferrer'>
                    <FontAwesomeIcon icon={faFacebookF}/>
                </a>

                <a href="https://www.instagram.com/s4hk/" className="social-icon" target="_blank" rel='noreferrer'>
                    <FontAwesomeIcon icon={faInstagram}/>
                </a>

                <a href="https://twitter.com/mr_s4hk" className="social-icon" target="_blank" rel='noreferrer'>
                    <FontAwesomeIcon icon={faTwitter}/>
                </a>

                <a href="https://www.linkedin.com/in/seifo/" className="social-icon" target="_blank" rel='noreferrer'>
                    <FontAwesomeIcon icon={faLinkedinIn}/>
                </a>

                <a href="https://codepen.io/s4hk" className="social-icon" target="_blank" rel='noreferrer'>
                    <FontAwesomeIcon icon={faCodepen}/>
                </a>
                <span className="line"></span>
            </div>

            <div className="mail-parent">
                <a href="mailto:s4hk@yahoo.com">s4hk@yahoo.com</a>
                <span className="line"></span>

            </div>
        </>
    );
}

export default Contact;