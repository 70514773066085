import React from 'react';
import { Container , Row , Col } from 'react-bootstrap';
import './HeroSec.scss';
const HeroSec = () => {
    return ( 
        <>
            <section className="hero-sec">
                <Container>
                    <Row>
                        <Col>
                            <div className="cont-txt">
                                <h3 className="green-txt">Hi, my name is</h3>
                                <h1>Seif Atalla</h1>
                                <h2>I build things for the web.</h2>
                                <p className="entry-txt">
                                    Computer Engineer Professionally connected with the web development industry and information technology for many years.
                                    Well-organised person, problem solver, independent employee with high attention to detail. Interested in the entire frontend spectrum and working on ambitious projects with positive people.
                                </p>

                                <a href="https://seif.coded.website/resume/seif.docx" download="Seif-Atalla" className="btn transparent-btn">Check Out My Resume</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default HeroSec;