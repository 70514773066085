import React from 'react';
import HeroSec from './HeroSec/HeroSec';
import AboutSec from './AboutSec/AboutSec';
import WorkSec from './WorkSec/WorkSec';
import ProjectSec from './ProjectsSec/ProjectSec';
import OtherProjects from './OtherProjects/OtherProjects';
const Home = () => {
    return ( 
        <>
            <HeroSec />
            <AboutSec/>
            <WorkSec/>
            <ProjectSec/>
            {/* <OtherProjects/> */}
        </>
    );
}

export default Home;