import React from 'react';
import { Container , Row ,Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faJs , faGitSquare , faReact , faAngular , faWordpress , faPhp , faCss3Alt , faSass , faBootstrap , faHtml5 } from '@fortawesome/free-brands-svg-icons';
import logo from '../../../Assets/img/logo.svg';
import './AboutSec.scss';
const AboutSec = () => {
    return ( 
        <>
        
        <section className="aboutus-sec" id="aboutMe">
            <Container>
                <Row>

                    <Col xs={12} lg={10} className="mx-auto">
                        <div className="cont">
                            <div className="cont-txt">
                                <div className="sec-heading">
                                    <h2>
                                        <span className="green-txt">01. </span>  
                                        About Me
                                    </h2>
                                    <span className="line"></span>
                                </div>
                                <p>
                                    Accomplished front-end & WordPress developer with 3+ years of experience building websites, HTML5, CSS3,JS ,Reactjs and various web scripting technologies, web standards and project management. Provide a diverse range of services including custom theme/application/plugin development, SEO/SEM, and branding to an array of clients from a variety of industries. Enthusiastic web professional motivated by challenging projects and deadlines.
                                </p>

                                <p>Here are a few technologies I’ve been working with recently:</p>

                                <ul className="technologies">
                                    <li>
                                        <FontAwesomeIcon icon={faHtml5}/>
                                        <span>Html</span>
                                    </li>
                                    
                                    <li>
                                        <FontAwesomeIcon icon={faCss3Alt}/>
                                        <span>Css</span>
                                    </li>

                                    <li>
                                        <FontAwesomeIcon icon={faBootstrap}/>
                                        <span>Bootstrap</span>
                                    </li>

                                    <li>
                                        <FontAwesomeIcon icon={faSass}/>
                                        <span>Sass</span>
                                    </li>

                                    <li>
                                        <FontAwesomeIcon icon={faJs}/>
                                        <span>JavaScript (ES6+)</span>
                                    </li>

                                    <li>
                                        <FontAwesomeIcon icon={faReact}/>
                                        <span>React</span>
                                    </li>

                                    <li>
                                        <FontAwesomeIcon icon={faAngular}/>
                                        <span>Angular</span>
                                    </li>

                                    <li>
                                        <FontAwesomeIcon icon={faPhp}/>
                                        <span>Php</span>
                                    </li>

                                    <li>
                                        <FontAwesomeIcon icon={faWordpress}/>
                                        <span>Wordpress</span>
                                    </li>

                                    <li>
                                        <FontAwesomeIcon icon={faGitSquare}/>
                                        <span>Git</span>
                                    </li>

                                </ul>
                            </div>
                            <div className="cont-img">
                                <div className="img-parent">
                                    <img src={logo} alt="logo" />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>  
            
        </>
    );
}

export default AboutSec;