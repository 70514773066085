import Contact from './Components/Contact/Contact';
import Header from './Components/Layouts/Header/Header';
import Home from './Components/Home/Index';
import ContactSec from './Components/Layouts/Footer/Footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <Home/>
      <Contact/>
      <ContactSec/>
    </div>
  );
}

export default App;
