import React from 'react';
import { Container ,  Navbar , Nav} from 'react-bootstrap';
import './Header.scss';
const Header = () => {
    return (
        <>
                <header  className="fixed-top">
                    <Container fluid className="header-container">
                    <Navbar expand="lg" >
                        <Navbar.Brand href="#">
                            <span>&lt; Seif /></span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link href="#aboutMe"><span className="green-txt">01.</span>About</Nav.Link>
                            <Nav.Link href="#workSec"><span className="green-txt">02.</span>Experience</Nav.Link>
                            <Nav.Link href="#projects"><span className="green-txt">03.</span>Work</Nav.Link>
                            <Nav.Link href="#contact"><span className="green-txt">04.</span>Contact</Nav.Link>
                            <a href="https://seif.coded.website/resume/seif.docx" download="Seif-Atalla" className="btn transparent-btn">Resume</a>
                        </Nav>
                        </Navbar.Collapse>
                    </Navbar>

                    </Container>
                </header>
        </>
    );
}

export default Header;